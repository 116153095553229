import { library, dom } from "@fortawesome/fontawesome-svg-core";

import {
  faGasPump,
  faChargingStation,
  faTachometerAltFast,
  faTachometerAltFastest,
  faCogs,
  faCalendar,
  faCalendarAlt,
  faCaravan,
  faChairOffice as faChairOfficeLight,
  faSlidersH,
  faSlidersV,
  faTimes,
  faBars,
  faAngleLeft,
  faAngleRight,
  faAngleDown,
  faAngleUp,
  faInfoCircle,
  faWindowRestore,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faArrowUp,
  faEnvelope,
  faCarGarage,
  faGlasses,
  faHorse as faHorseLight,
  faTools,
  faClock as faClockLight,
  faComments,
  faCar as faCarLight,
  faCameraAlt,
  faKey,
  faPhone,
  faSpinnerThird,
  faHotel,
  faHeart as faHeartLight,
  faLeaf,
  faLifeRing,
  faMapMarkerAlt as faMapMarkerAltLight,
  faTruck,
  faSnowflake,
  faSun,
  faTireFlat,
  faTirePressureWarning,
  faTireRugged,
  faUsers as faUsersLight,
  faSearch as faSearchLight,
  faShoppingCart as faShoppingCartLight,
  faCreditCard,
  faCube,
  faCarCrash,
  faTire,
  faUser as faUserLight,
  faCloudDrizzle,
  faVolume,
  faWeightHanging,
  faSignOut,
  faSignOutAlt,
  faBoxCheck,
  faReceipt,
  faStar as faStarLight,
  faPlus,
  faRoad,
  faCheck as faCheckLight,
  faBookmark as faBookmarkLight,
  faFileCertificate,
  faShieldCheck,
  faBallotCheck,
  faStarfighterAlt,
  faCarMechanic,
  faFragile,
  faHammer,
  faSprayCan,
  faToolbox,
  faBoxFragile,
  faSwordsLaser,
  faFillDrip,
  faExpandWide,
  faGem,
  faBed,
  faRampLoading,
  faTrailer,
  faTruckLoading,
  faArrowsH,
  faBedBunk,
  faBatteryBolt,
  faCoins,
  faRv,
  faBedAlt,
  faBolt,
  faShareSquare,
  faPrint,
  faCars,
  faChevronRight,
  faEarMuffs,
  faBalanceScale,
  faMoneyCheck,
  faSwatchbook,
  faCarMirrors,
  faCircle as faCircleLight,
  faQuestionCircle as faQuestionCircleLight,
  faArrowsRotate,
  faSunBright,
  faArrowRightToBracket,
  faSteeringWheel,
  faArrowUpArrowDown,
} from "@fortawesome/pro-light-svg-icons";

import {
  faBell as faBellSolid,
  faBellOn as faBellOnSolid,
  faCheck,
  faTrash,
  faMobileAlt,
  faImages,
  faMapMarkerAlt,
  faCar,
  faClock,
  faCircle,
  faUsers,
  faPlayCircle,
  faHeart,
  faTag,
  faTags,
  faTriangleExclamation,
  faStar,
  faBookmark,
  faRoad as faRoadSolid,
  faQuestion,
  faTire as faTireSolid,
  faQrcode,
  faPhone as faPhoneSolid,
  faEnvelope as faEnvelopeSolid,
  faShareAlt,
  faLeaf as faLeafSolid,
  faComment,
  faChevronRight as faChevronRightSolid,
  faShippingFast,
  faChevronLeft as faChevronLeftSolid,
  faXmark as faXmarkSolid,
  faReceipt as faReceiptSolid,
  faSpinner as faSpinnerSolid,
  faArrowRight as faArrowRightSolid,
  faChevronDown as faChevronDownSolid,
  faTirePressureWarning as faTirePressureWarningSolid,
  faUser as faUserSolid,
  faImage as faImageSolid,
  faHouse as faHouseSolid,
  faCreditCard as faCreditCardSolid,
  faCarMirrors as faCarMirrorsSolid,
  faCalendarDays as faCalendarDaysSolid,
  faRectangleHistory as faRectangleHistorySolid,
  faCog as faCogSolid,
  faCircleQuestion as faCircleQuestionSolid,
  faScaleBalanced as faScaleBalancedSolid,
  faCheckCircle as faCheckCircleSolid,
  faDoNotEnter as faDoNotEnterSolid,
  faBadgeCheck as faBadgeCheckSolid,
  faBadgeDollar as faBadgeCheckDollar,
  faRotateRight,
  faPowerOff
} from "@fortawesome/pro-solid-svg-icons";

import {
  faFacebookSquare,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import {
  faTimes as faTimesRegular,
  faUser,
  faSearch,
  faShoppingCart,
  faQuestionCircle,
  faCircle as faCircleRegular,
  faArrowAltCircleDown,
  faLink,
  faEnvelope as faEnvelopeRegular,
  faPhone as faPhoneRegular,
  faBalanceScale as faBalanceScaleRegular,
  faHeart as faHeartRegular,
  faCarGarage as faCarGarageRegular,
  faSunBright as faSunBrightRegular,
  faChevronDown as faChevronDownRegular,
  faCalendar as faCalendarRegular,
  faCameraAlt as faCameraAltRegular,
  faTrashAlt as faTrashAltRegular,
  faBadgeCheck as faBadgeCheckRegular,
  faChevronRight as faChevronRightRegular,
  faCheck as faCheckRegular,
  faScaleBalanced as faScaleBalancedRegular,
  faHouse as faHouseRegular,
  faCreditCard as faCreditCardRegular,
  faTag as faTagRegular,
  faCarMirrors as faCarMirrorsRegular,
  faCalendarDays as faCalendarDaysRegular,
  faBell as faBellRegular,
  faRectangleHistory as faRectangleHistoryRegular,
  faCog as faCogRegular,
  faCircleQuestion as faCircleQuestionRegular,
  faFileContract as faFileContractRegular,
  faSpinner as faSpinnerRegular,
  faSpinnerThird as faSpinnerThirdRegular,
  faMapMarkerAlt as faMapMarkerAltRegular,
  faClock as faClockRegular,
  faUsers as faUsersRegular,
  faAngleLeft as faAngleLeftRegular,
  faAngleRight as faAngleRightRegular,
  faRecycle,
  faBadgeDollar as faBadgeCheckDollarRegular,
  faCircleParking,
  faLightbulbOn,
} from "@fortawesome/pro-regular-svg-icons";

library.add(
  faSearch,
  faGasPump,
  faChargingStation,
  faTachometerAltFast,
  faTachometerAltFastest,
  faCogs,
  faCalendar,
  faCalendarAlt,
  faChairOfficeLight,
  faBellSolid,
  faBellOnSolid,
  faSlidersH,
  faSlidersV,
  faTimes,
  faTimesRegular,
  faBars,
  faCheck,
  faAngleLeft,
  faAngleRight,
  faAngleLeftRegular,
  faAngleRightRegular,
  faAngleDown,
  faAngleUp,
  faInfoCircle,
  faWindowRestore,
  faFacebookSquare,
  faInstagram,
  faTrash,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faArrowUp,
  faEnvelope,
  faCarGarage,
  faMobileAlt,
  faImages,
  faGlasses,
  faMapMarkerAlt,
  faMapMarkerAltRegular,
  faMapMarkerAltLight,
  faClock,
  faCircle,
  faCircleRegular,
  faUsers,
  faTools,
  faClockLight,
  faClockRegular,
  faCarLight,
  faComments,
  faCar,
  faCameraAlt,
  faKey,
  faPhone,
  faPlayCircle,
  faSpinnerThird,
  faHorseLight,
  faHotel,
  faLeaf,
  faLeafSolid,
  faLifeRing,
  faTruck,
  faUsersLight,
  faCube,
  faTire,
  faCarCrash,
  faHeart,
  faHeartLight,
  faTags,
  faSnowflake,
  faSun,
  faSunBright,
  faTireFlat,
  faTirePressureWarning,
  faTireRugged,
  faTireSolid,
  faUser,
  faShoppingCart,
  faSearchLight,
  faTriangleExclamation,
  faShoppingCartLight,
  faCreditCard,
  faUserLight,
  faCloudDrizzle,
  faVolume,
  faWeightHanging,
  faStarLight,
  faStar,
  faSignOut,
  faBoxCheck,
  faReceipt,
  faPlus,
  faRoad,
  faRoadSolid,
  faPrint,
  faCheckLight,
  faSignOutAlt,
  faBookmark,
  faBookmarkLight,
  faQuestion,
  faQuestionCircle,
  faFileCertificate,
  faShieldCheck,
  faBallotCheck,
  faStarfighterAlt,
  faCarMechanic,
  faFragile,
  faHammer,
  faSprayCan,
  faToolbox,
  faBoxFragile,
  faSwordsLaser,
  faFillDrip,
  faExpandWide,
  faGem,
  faCaravan,
  faBed,
  faRampLoading,
  faTrailer,
  faTruckLoading,
  faArrowsH,
  faBedBunk,
  faBatteryBolt,
  faCoins,
  faRv,
  faBedAlt,
  faBolt,
  faShareSquare,
  faQrcode,
  faCars,
  faChevronRight,
  faEarMuffs,
  faBalanceScale,
  faLinkedinIn,
  faPhoneSolid,
  faEnvelopeSolid,
  faShareAlt,
  faArrowAltCircleDown,
  faLink,
  faTwitter,
  faChevronRightSolid,
  faMoneyCheck,
  faTag,
  faComment,
  faSwatchbook,
  faEnvelopeRegular,
  faPhoneRegular,
  faBalanceScaleRegular,
  faHeartRegular,
  faShippingFast,
  faChevronRightSolid,
  faChevronLeftSolid,
  faCarGarageRegular,
  faSunBrightRegular,
  faCarMirrors,
  faCircleLight,
  faChevronDownRegular,
  faArrowsRotate,
  faCalendarRegular,
  faCameraAltRegular,
  faTrashAltRegular,
  faBadgeCheckRegular,
  faChevronRightRegular,
  faCheckRegular,
  faScaleBalancedRegular,
  faHouseRegular,
  faCreditCardRegular,
  faTagRegular,
  faCarMirrorsRegular,
  faCalendarDaysRegular,
  faBellRegular,
  faRectangleHistoryRegular,
  faCogRegular,
  faCircleQuestionRegular,
  faXmarkSolid,
  faReceiptSolid,
  faSpinnerSolid,
  faArrowRightSolid,
  faChevronDownSolid,
  faTirePressureWarningSolid,
  faUserSolid,
  faUsersRegular,
  faImageSolid,
  faHouseSolid,
  faCreditCardSolid,
  faCarMirrorsSolid,
  faCalendarDaysSolid,
  faRectangleHistorySolid,
  faCogSolid,
  faCircleQuestionSolid,
  faScaleBalancedSolid,
  faCheckCircleSolid,
  faDoNotEnterSolid,
  faBadgeCheckSolid,
  faFileContractRegular,
  faBadgeCheckDollar,
  faQuestionCircleLight,
  faSpinnerRegular,
  faSpinnerThirdRegular,
  faRotateRight,
  faCircleLight,
  faArrowsRotate,
  faRecycle,
  faBadgeCheckDollarRegular,
  faYoutube,
  faArrowRightToBracket,
  faPowerOff,
  faCircleParking,
  faLightbulbOn,
  faSteeringWheel,
  faArrowUpArrowDown,
);
dom.watch();
