var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "employee-channel-list" }, [
    _c("div", { staticClass: "employee-channel-list__heading" }, [
      _vm._v("\n    " + _vm._s(_vm.heading) + "\n  ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "employee-channel-list__actions" }, [
      _c("div", { staticClass: "employee-channel-list__tabs" }, [
        _c(
          "div",
          {
            staticClass: "employee-channel-list__select",
            class: {
              "employee-channel-list__select--active":
                _vm.departmentSelected === _vm.DepartmentKeys.Sale
            },
            on: {
              click: function($event) {
                return _vm.setDepartment(_vm.DepartmentKeys.Sale)
              }
            }
          },
          [_vm._v("\n        " + _vm._s(_vm.DepartmentKeys.Sale) + "\n      ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "employee-channel-list__select",
            class: {
              "employee-channel-list__select--active":
                _vm.departmentSelected === _vm.DepartmentKeys.Mechanic
            },
            on: {
              click: function($event) {
                return _vm.setDepartment(_vm.DepartmentKeys.Mechanic)
              }
            }
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.DepartmentKeys.Mechanic) +
                " " +
                _vm._s(_vm.isCamping ? " & Butik" : "") +
                "\n      "
            )
          ]
        ),
        _vm._v(" "),
        _vm.displayDamage
          ? _c(
              "div",
              {
                staticClass: "employee-channel-list__select",
                class: {
                  "employee-channel-list__select--active":
                    _vm.departmentSelected === _vm.DepartmentKeys.Damage
                },
                on: {
                  click: function($event) {
                    return _vm.setDepartment(_vm.DepartmentKeys.Damage)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.DepartmentKeys.Damage) + "\n      "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.displayRecond
          ? _c(
              "div",
              {
                staticClass: "employee-channel-list__select",
                class: {
                  "employee-channel-list__select--active":
                    _vm.departmentSelected === _vm.DepartmentKeys.Recond
                },
                on: {
                  click: function($event) {
                    return _vm.setDepartment(_vm.DepartmentKeys.Recond)
                  }
                }
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.DepartmentKeys.Recond) + "\n      "
                )
              ]
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c(
        "label",
        {
          staticClass: "employee-channel-list__input",
          attrs: { for: "employeeSearch" }
        },
        [
          _vm.loading
            ? _c("font-awesome-icon", {
                attrs: { icon: _vm.faSpinnerThird, spin: true }
              })
            : _c("font-awesome-icon", { attrs: { icon: _vm.faSearch } }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchString,
                expression: "searchString"
              }
            ],
            attrs: {
              type: "text",
              name: "employeeSearch",
              id: "employeeSearch",
              placeholder: _vm.lang.Contact.EmployeeListSearchPlaceholder
            },
            domProps: { value: _vm.searchString },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.searchString = $event.target.value
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "employee-channel-list__quantity-text" }, [
      _vm._v("\n    " + _vm._s(_vm.quantityText) + "\n  ")
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "employee-channel-list__list" },
      _vm._l(_vm.employeeList, function(employee) {
        return _c(
          "a",
          {
            staticClass: "employee-channel-list__item",
            attrs: {
              "data-modal-source": !employee.digitalBusinessCard && "ajax",
              "data-target": !employee.digitalBusinessCard && employee.modalUrl,
              href: employee.digitalBusinessCard && employee.permalink,
              target: employee.digitalBusinessCard && "_blank"
            }
          },
          [
            _c("div", { staticClass: "employee-channel-list__item__image" }, [
              _c("img", {
                attrs: {
                  src:
                    employee.image +
                    "?width=90&mode=canvas&404=/globalassets/anstallda-bilder/fallback_person.jpg"
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "employee-channel-list__item__content" }, [
              _c("div", { staticClass: "employee-channel-list__item__name" }, [
                _vm._v(
                  "\n          " + _vm._s(employee.fullName) + "\n        "
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "employee-channel-list__item__title" }, [
                _vm._v("\n          " + _vm._s(employee.title) + "\n        ")
              ])
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "employee-channel-list__pagination-container" }, [
      _c("div", { staticClass: "employee-channel-list__pagination-text" }, [
        _vm._v("\n      " + _vm._s(_vm.paginationQuantityText) + "\n    ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "employee-channel-list__pagination-bar" }, [
        _c("div", {
          staticClass: "employee-channel-list__pagination-bar__background"
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "employee-channel-list__pagination-bar__active",
          style: "width: " + _vm.barWidth + "%;"
        })
      ]),
      _vm._v(" "),
      _vm.nextPage
        ? _c(
            "div",
            {
              staticClass:
                "link link--icon shaded-expand__btn link-toggler-click",
              on: { click: _vm.onNext }
            },
            [
              _c("span", { staticClass: "shaded-expand__btn--expand" }, [
                _vm._v(_vm._s(_vm.lang.Contact.QuantityPaginationCta))
              ]),
              _vm._v(" "),
              _c("i", { staticClass: "fal fa-angle-down" })
            ]
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }