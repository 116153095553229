import { render, staticRenderFns } from "./Addons.vue?vue&type=template&id=0afca988&"
import script from "./Addons.vue?vue&type=script&lang=js&"
export * from "./Addons.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\agent\\_work\\2\\s\\src\\Holmgrens.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0afca988')) {
      api.createRecord('0afca988', component.options)
    } else {
      api.reload('0afca988', component.options)
    }
    module.hot.accept("./Addons.vue?vue&type=template&id=0afca988&", function () {
      api.rerender('0afca988', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/apps/service-agreement-block/Addons.vue"
export default component.exports