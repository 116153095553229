<template>
  <VcFormStack :label="title">
    <VcFormSelect
      :options="options"
      :value="selected"
      labelProp="name"
      valueProp="value"
      prompt="Ingen anläggning vald"
      @change="select">
    </VcFormSelect>
  </VcFormStack>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { VcFormSelect, VcFormStack } from '@holmgrensbil/vue-components';
import ServicesApi from '../../models/ServicesApi';

export default {
  components: {
    VcFormSelect,
    VcFormStack,
  },
  computed: {
    ...mapGetters({
      compatibleOptions: 'departments/compatibleOptions',
    }),

    ...mapState({
      title: state => state.departments.title,
      selected: state => state.departments.selected,
      selectedSection: state => state.sections.selected,
      selectedBrand: state => state.brands.selected,
      selectedService: state => state.services.selected,
      carInfo: state => state.regNr.item,
      departmentsByRegNr: state => state.departments.departmentsByRegNr,
    }),

    hasCarInfo() {
      return this.carInfo && this.carInfo.registrationNumber;
    },

    options() {
      if (this.hasCarInfo) {
        return this.departmentsByRegNr;
      } else {
        const brand = this.selectedBrand;
        const service = this.selectedService;
        const departmentServiceFilter = this.selectedSection.departmentServiceFilter;
  
        return this.compatibleOptions({ brand, service, departmentServiceFilter });
      }
    },
  },
  methods: {
    ...mapActions({
      select: 'departments/select',
      fetchByRegNr: 'departments/fetchDepartmentsByRegNr',
    }),
  }
}
</script>
