<template>
  <div class="row">
    <div class="contact__selected col-lg-8">
      <h4>
        {{hideSections ? 'Ta kontakt' : section.name}}
      </h4>
      <div class="row">
        <div class="col-md-4">
          <component v-for="selection in selections"
            :key="selection.type"
            :is="selection.component">
          </component>
        </div>
        <div class="col-md-8">
          <div v-if="selectedDepartment" class="contact__department">
            <div class="contact__department-content">
              <component
                :is="SectionHandleComponents[section.sectionHandle]"
                :department="selectedDepartment">
              </component>
            </div>

            <DepartmentActions
              :hide-sections="hideSections"
              :section="section"
              :department="selectedDepartment"
              :regNr="regNr">
            </DepartmentActions>
          </div>
          <div v-else class="contact__department-placeholder">
            {{section.placeholder}}
          </div>
        </div>
      </div>
    </div>
    <div class="contact__faq col-lg-4" v-if="section.questions && section.questions.length">
      <h4>
        Vanliga frågor och svar
      </h4>
      <SectionFaq :questions="section.questions">
      </SectionFaq>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import DepartmentActions from './DepartmentActions.vue';
import SectionFaq from './SectionFaq.vue';
import SelectionTypes from './constants/SelectionTypes';
import SelectionTypeComponents from './constants/SelectionTypeComponents';
import SectionHandleComponents from './constants/SectionHandleComponents';

export default {
  components: {
    DepartmentActions,
    SectionFaq,
  },
  props: {
    section: Object,
    hideSections: Boolean,
  },
  data() {
    return {
      SectionHandleComponents,
    };
  },
  computed: {
    ...mapGetters({
      selectedDepartment: 'departments/selectedItem',
    }),

    ...mapState({
      carInfo: state => state.regNr.item,
    }),
    regNr() {
      return this.carInfo && this.carInfo.registrationNumber;
    },
    selections() {
      const items = [
        this.section.firstSelection,
        this.section.secondSelection,
      ].filter(Boolean);

      return items.map(item => ({
        type: item,
        component: SelectionTypeComponents[item],
      }));
    },
  },
}
</script>
