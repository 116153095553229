import { render, staticRenderFns } from "./SavedVehicles.vue?vue&type=template&id=36848191&"
import script from "./SavedVehicles.vue?vue&type=script&lang=js&"
export * from "./SavedVehicles.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\agent\\_work\\2\\s\\src\\Holmgrens.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('36848191')) {
      api.createRecord('36848191', component.options)
    } else {
      api.reload('36848191', component.options)
    }
    module.hot.accept("./SavedVehicles.vue?vue&type=template&id=36848191&", function () {
      api.rerender('36848191', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/apps/my-pages/sections/SavedVehicles.vue"
export default component.exports