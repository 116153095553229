import axios from '../../../../axios';
import RequestStates from '../../../../constants/RequestStates';
import ServicesApi from '../../../../models/ServicesApi';
import ServiceFlows from '../../constants/ServiceFlows';

const state = {
  options: [],
  title: null,
  selected: null,
  items: [],
  fetchState: null,
  departmentsByRegNr: [],
  preselectedBrand: null,
};

const getters = {
  compatibleOptions: state => ({ service, brand, departmentServiceFilter }) => {
    const brandVal = state.preselectedBrand ?? brand?.value;
    return state.options.filter(option => {
      const hasBrand = brandVal ? option.brands.find(b => b === brandVal) : false;
      const hasService = service ? option.services.find(s => s === service.value) : false;
      const hasServiceFilters = departmentServiceFilter.length ? departmentServiceFilter.some(dsf => option.services.includes(dsf)) : true;

      if (service && brandVal) {
        return hasService && hasBrand && hasServiceFilters;
      } else if (service) {
        return hasService && hasServiceFilters;
      } else if (brandVal) {
        return hasBrand && hasServiceFilters;
      }
    });
  },

  selectedItem: state => {
    if (state.selected) {
      return state.items.find(item => item.id === state.selected.value);
    }
  },
};

const actions = {
  load({ commit }, data) {
    commit('received', data);
  },

  select({ commit, dispatch, rootState }, item) {
    commit('selected', item);

    const brand = rootState.brands.selected && rootState.brands.selected.value;
    let service = rootState.services.selected && rootState.services.selected.value;

    if (!service) {
      const dsf = rootState.sections.selected.departmentServiceFilter;

      if (dsf.length) {
        service = dsf[0];
      }
    }

    dispatch('fetchDepartment', {
      departmentNr: item.value,
      service,
      brand,
    });
  },

  deselect({ commit, state }) {
    if (state.selected) {
      commit('deselected');
    }
  },

  async fetchDepartment({ commit, state }, { departmentNr, service, brand }) {
    if (state.items.find(item => item.id === departmentNr)) {
      return;
    }

    if (state.selected.services && !state.selected.services.includes(service)) {
      service = state.selected.services[0];
    }

    commit('fetchDepartmentRequest');

    try {
      const data = {
        departmentNr,
        service,
        brand,
      };
      const res = await axios.post('/api/Contact/GetContactDepartment', data);
      res.data.id = departmentNr; // why no id in response? :(
      commit('fetchDepartmentSuccess', res.data);
    } catch (error) {
      commit('fetchDepartmentFailure', { error });
    }
  },
  async fetchDepartmentsByRegNr({ commit, rootState }, { regNr, service }) {

    const selectedService = service || rootState.sections.selected.sectionHandle;

    try {
      const params = {
        regNr: regNr,
        service: ServiceFlows[selectedService]
      };

      const res = await ServicesApi.fetchDepartments(params);
      commit('fetchedDepartmentsByRegNr', res.data);

    } catch (e) {
      console.error(e);
    }
  },
  clearDepartmentsByRegNr({ commit, dispatch, rootState }, service) {
    commit('clearedDepartmentsByRegNr');
    if (rootState.regNr.item && rootState.regNr.item.registrationNumber) {
      dispatch('fetchDepartmentsByRegNr', { regNr: rootState.regNr.item.registrationNumber, service })
    }
  },
  preselectedBrand({ commit }, brand) {
    commit('preselectedBrand', brand);
  }
};

const mutations = {
  received(state, data) {
    state.options = data.options;
    state.title = data.title;
  },

  selected(state, item) {
    state.selected = item;
  },

  deselected(state) {
    state.selected = null;
  },

  preselectedBrand(state, brand) {
    state.preselectedBrand = brand;
  },

  fetchDepartmentRequest(state) {
    state.fetchDepartmentState = RequestStates.Request;
  },

  fetchDepartmentSuccess(state, department) {
    state.fetchDepartmentState = RequestStates.Success;
    state.items = [
      ...state.items,
      department,
    ];
  },

  fetchDepartmentFailure(state, { error }) {
    state.fetchDepartmentState = RequestStates.Failure;
    console.error(error);
  },

  fetchedDepartmentsByRegNr(state, data) {
    state.departmentsByRegNr = data;
  },

  clearedDepartmentsByRegNr(state) {
    state.departmentsByRegNr = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
