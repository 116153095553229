var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-pages__section add-my-vehicle" },
    [
      _c("h4", { staticClass: "u-fw-medium" }, [_vm._v("Fordonets regnr")]),
      _vm._v(" "),
      _c("span", { staticClass: "my-pages__section__title" }, [
        _vm._v("Ange ditt fordons regnr för att spara fordon")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "regnr-input" }, [
        _c(
          "div",
          { staticClass: "input-group" },
          [
            _vm._m(0),
            _vm._v(" "),
            _c("VcFormStack", { attrs: { label: "Regnr" } }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.regNr,
                    expression: "regNr"
                  }
                ],
                staticClass: "form-control",
                class: { "form-control--invalid": _vm.invalid },
                attrs: { placeholder: "ABC123", type: "text" },
                domProps: { value: _vm.regNr },
                on: {
                  keyup: function($event) {
                    return _vm.changeRegNr()
                  },
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.regNr = $event.target.value
                  }
                }
              })
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.lookupResult
        ? _c(
            "div",
            {
              staticClass:
                "feedback-message feedback-message--slim feedback-message--success"
            },
            [
              _c("div", { staticClass: "lookup-result" }, [
                _c("div", { staticClass: "lookup-result__item" }, [
                  _c("label", [_vm._v("\n          Märke\n        ")]),
                  _vm._v(
                    "\n        " + _vm._s(_vm.lookupResult.brand) + "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "lookup-result__item" }, [
                  _c("label", [
                    _vm._v("\n          Modell och version\n        ")
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.lookupResult.model) +
                      " " +
                      _vm._s(_vm.lookupResult.version) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "lookup-result__item" }, [
                  _c("label", [_vm._v("\n          Produktionsår\n        ")]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.lookupResult.modelYear) +
                      "\n      "
                  )
                ])
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.lookupResult &&
      _vm.lookupResult.trimLevels &&
      _vm.lookupResult.trimLevels.length > 1
        ? _c(
            "VcFormStack",
            {
              attrs: {
                label: "Välj utrustningspaket",
                required: _vm.lookupResult.trimLevels.length > 1
              }
            },
            [
              _c("VcFormSelect", {
                attrs: {
                  options: _vm.lookupResult.trimLevels,
                  value: _vm.trimPackage,
                  prompt: "Välj utrustningspaket",
                  valueProp: "trimLevel",
                  labelProp: "trimLevel"
                },
                on: {
                  change: function($event) {
                    return _vm.onTrimPackageChanged($event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn--primary btn--block",
          class: { disabled: _vm.mayNotAdd },
          attrs: { disabled: _vm.mayNotAdd },
          on: {
            click: function($event) {
              return _vm.addVehicle()
            }
          }
        },
        [_vm._v("\n    Lägg till fordon\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("div", { staticClass: "regnr-input__symbol" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }