import { render, staticRenderFns } from "./SectionContentImages.vue?vue&type=template&id=1b317a91&"
import script from "./SectionContentImages.vue?vue&type=script&lang=js&"
export * from "./SectionContentImages.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\agent\\_work\\2\\s\\src\\Holmgrens.Web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1b317a91')) {
      api.createRecord('1b317a91', component.options)
    } else {
      api.reload('1b317a91', component.options)
    }
    module.hot.accept("./SectionContentImages.vue?vue&type=template&id=1b317a91&", function () {
      api.rerender('1b317a91', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Scripts/apps/trade-in-camping/SectionContentImages.vue"
export default component.exports