import Axios from '../../../../axios';
import RequestStates from '../../../../constants/RequestStates';
import FieldTypes from '../constants/FieldTypes';

const state = {
  fetchState: null,
  result: null,
  resultSuccess: null,
};

const getters = {};

const setFields = (data, dispatch) => {
  const fieldValues = {
    [FieldTypes.RegNr]: data.registrationNumber,
    [FieldTypes.RegDate]: data.registrationDate,
    [FieldTypes.Model]: data.model,
    [FieldTypes.Brand]: { name: data.brand, value: data.brandCode },
    [FieldTypes.Version]: data.version,
    [FieldTypes.ModelYear]: data.modelYear,
  };

  Object.entries(fieldValues).forEach(([type, value]) => {
    dispatch('fields/set', { type, value }, { root: true });
  });

  const trimOptions = data.trimLevels.map(level => level.trimLevel);

  trimOptions.push('Annan nivå/paket');

  dispatch('fields/set', { type: FieldTypes.EquipmentLevel, options: trimOptions }, { root: true });
};

const actions = {
  async fetch({ commit, dispatch, state }, regNr) {
    commit('fetchRequest');

    try {
      let config = {
        headers: {
          "Ocp-Apim-Subscription-Key": "6a5756fffe494a768b0a5a870854ef50"
        }
      }
      const res = await Axios.get(`https://api.holmgrensbil.se/ecu/integration/data/autonet/${regNr}`, config);
      commit('fetchSuccess', res.data);

      if (state.resultSuccess) {
        setFields(res.data, dispatch);
      }

      return state.resultSuccess;
    } catch (error) {
      commit('fetchFailure', { error });
    }
  },
};

const mutations = {
  fetchRequest(state) {
    state.fetchState = RequestStates.Request;
  },

  fetchSuccess(state, result) {
    state.fetchState = RequestStates.Success;
    state.result = result;
    state.resultSuccess = !!result.brand;
  },

  fetchFailure(state) {
    state.fetchState = RequestStates.Failure;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
