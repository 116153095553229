<template>
  <div class="my-pages__section add-my-vehicle">
    <h4 class="u-fw-medium">Fordonets regnr</h4>
    <span class="my-pages__section__title">Ange ditt fordons regnr för att spara fordon</span>
    <div class="regnr-input">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="regnr-input__symbol"></div>
        </div>
        <VcFormStack label="Regnr">
          <input
            v-model="regNr"
            placeholder="ABC123"
            :class="{'form-control--invalid': invalid}"
            @keyup="changeRegNr()"
            type="text"
            class="form-control">
        </VcFormStack>


      </div>
    </div>

    <div class="feedback-message feedback-message--slim feedback-message--success" v-if="lookupResult">
      <div class="lookup-result">
        <div class="lookup-result__item">
          <label>
            Märke
          </label>
          {{lookupResult.brand}}
        </div>
        <div class="lookup-result__item">
          <label>
            Modell och version
          </label>
          {{lookupResult.model}} {{lookupResult.version}}
        </div>
        <div class="lookup-result__item">
          <label>
            Produktionsår
          </label>
          {{lookupResult.modelYear}}
        </div>
      </div>
    </div>

    <VcFormStack
      label="Välj utrustningspaket"
      :required="lookupResult.trimLevels.length > 1"
      v-if="lookupResult && lookupResult.trimLevels && lookupResult.trimLevels.length > 1">
      <VcFormSelect
        :options="lookupResult.trimLevels"
        :value="trimPackage"
        prompt="Välj utrustningspaket"
        valueProp="trimLevel"
        labelProp="trimLevel"
        @change="onTrimPackageChanged($event)">
      </VcFormSelect>
    </VcFormStack>
    <button @click="addVehicle()" :disabled="mayNotAdd" :class="{'disabled': mayNotAdd}" class="btn btn--primary btn--block">
      Lägg till fordon
    </button>
  </div>
</template>

<script>
import { VcFormStack, VcFormSelect } from '@holmgrensbil/vue-components';
import Axios from '../../axios';
import Views from './constants/Views';
import { EventBus } from '../../utils/event-bus';
export default {
  components: {
    VcFormStack,
    VcFormSelect,
  },
  props: {
    currentUser: Object,
    lang: Object,
    vehicle: Object|null,
  },

  data() {
    return {
      regNr: '',
      invalid: false,
      lookupResult: null,
      trimPackage: null,
    }
  },
  computed: {
    mayNotAdd() {
      return !this.lookupResult || !this.trimPackage;
    }
  },
  methods: {
    async addVehicle() {
      try {
        const res = await Axios.post(`/api/mypages/myvehicles`, {
          registrationNumber: this.regNr.toUpperCase(),
          trimPackage: this.trimPackage
        });
        this.$emit('view', Views.LoggedIn);
        EventBus.$emit('my-vehicle-added');
      } catch(e) {
        console.log(e);
        this.lookupResult = null;
      }
    },
    async changeRegNr() {
      if (!this.regNr.match(/^\w{3}\d{2}(\w{1}|\d{1})$/g)) {
        return;
      }
      try {
        let config = {
          headers: {
            "Ocp-Apim-Subscription-Key": "6a5756fffe494a768b0a5a870854ef50"
          }
        }
        const res = await Axios.get(`https://api.holmgrensbil.se/ecu/integration/data/autonet/${this.regNr}`, config);
        this.trimPackage = res.data.trimLevels[0].trimLevel;
        this.lookupResult = res.data;

      } catch(e) {
        console.log(e);
        this.lookupResult = null;
      }

    },
    onTrimPackageChanged(e) {
      this.trimPackage = e.trimLevel;
    }
  }
}
</script>

